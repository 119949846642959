<template>
  <div>
    <Pane> </Pane>

    <a-card class="container">
      <div class="right">
        <router-link :to="$route.path + '/add'">
          <a-button type="primary">新增</a-button>
        </router-link>
      </div>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="会议室名称">
          <template slot-scope="text">
            <a-tooltip>
              <template slot="title"> 查看预约情况 </template>
              <router-link :to="`/oa/meeting?code=${text.code}`">
                {{ text.name }}
              </router-link>
            </a-tooltip>
          </template>
        </a-table-column>

        <a-table-column title="地点">
          <template slot-scope="text">
            <div class="left">
              <DataDictFinder
                dictType="meeting-room-location"
                :dictValue="text.location"
              />
              <span>
                {{ text.floor }}
              </span>
            </div>
          </template>
        </a-table-column>

        <a-table-column title="容纳人数" data-index="accommodation">
        </a-table-column>

        <a-table-column title="自动审核" data-index="autoReview" align="center">
          <template slot-scope="autoReview">
            <DataDictFinder
              dictType="boolean"
              :dictValue="String(autoReview)"
            />
          </template>
        </a-table-column>

        <a-table-column title="状态" data-index="state" align="center">
          <template slot-scope="state">
            <DataDictFinder
              dictType="meetingRoomState"
              :dictValue="String(state)"
            />
          </template>
        </a-table-column>

        <a-table-column title="备注" data-index="remark"> </a-table-column>

        <a-table-column align="right">
          <div style="padding-right: 7px" slot="title">操作</div>
          <template slot-scope="text">
            <private-button path="edit" :params="{ id: text.id }">
              <span>编辑</span>
            </private-button>

            <private-button
              path="delete"
              routeType="method"
              danger
              @clickButton="deleteText(text)"
            >
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/meeting";
export default {
  components: {},

  data() {
    return {
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这个会议室吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>